import React, { memo, useState } from "react";
import {
  Stack,
  Typography,
  Card,
  CardContent,
  Collapse,
  Button,
} from "investira.react.components";
import { InlineInfo, ModalInfo } from "../../../../components/molecules";
import { NATUREZA } from "@investirapri/financa.js/lib/enums";
import EmpresaSetores from "./EmpresaSetores";

const InfosSection = memo((props) => {
  const { pessoa } = props;

  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const visibleInfos = [
    { label: "Razão Social", value: pessoa.pessoa, copy: "icon" },
    { label: "CNPJ", value: pessoa.cnpj, variant: "cnpj", copy: "icon" },
    { label: "Compe", value: pessoa.compe },
    { label: "ISIN", value: pessoa.isin },
    {
      label: "Setores",
      value: pessoa.top_setor,
      component: ModalInfo,
      modalProps: {
        pessoaId: pessoa.pessoa_id,
      },
      modalContent: EmpresaSetores,
      type: "row",
      // searchProps: {
      //   path: "/empresas",
      //   search: {
      //     top_setor_id: pessoa.top_setor_id,
      //     setor_id: pessoa.setor_id,
      //   },
      // },
    },
    {
      label: "Subsetor",
      value: pessoa.setor?.setor,
      searchProps: {
        path: "/empresas",
        search: {
          setor_id: pessoa.setor_id,
        },
      },
    },
    {
      label: "Natureza",
      value: pessoa.juridica?.natureza
        ? NATUREZA[pessoa.juridica?.natureza].descricao
        : null,
      searchProps: {
        path: "/empresas",
        search: {
          setor_id: pessoa.setor_id,
        },
      },
    },
  ];

  const hiddenInfos = [
    {
      label: "Filiais",
      value: pessoa.juridica?.filiais,
    },
    { label: "CNAE", value: pessoa.setor_id, copy: "icon" },
    {
      label: "Fundação",
      value: pessoa.juridica?.constituicao,
      variant: "date",
    },
    { label: "Holding?", value: pessoa.juridica?.holding, variant: "bool" },
    { label: "Site", value: pessoa.infos?.site, variant: "site" },
    { label: "Telefone", value: pessoa.infos?.telefone, variant: "tel" },
    { label: "Endereço", value: pessoa.endereco, variant: "address" },
  ];

  return (
    <Stack flexGrow={1} sx={{ minWidth: "288px" }}>
      <Card sx={{ height: "100%" }}>
        <CardContent
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Stack spacing={2} sx={{ py: 2 }}>
            <Stack>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                sx={{ fontWeight: 600 }}
              >
                Informações da Empresa
              </Typography>
            </Stack>

            <Stack direction="column" sx={{ gap: 1 }}>
              {visibleInfos.map((info) => {
                const Component = info.component || InlineInfo;
                return <Component key={crypto.randomUUID()} {...info} />;
              })}

              <Collapse in={expanded}>
                <Stack direction="column" spacing={1}>
                  {hiddenInfos.map((info, index) => (
                    <InlineInfo key={index} {...info} />
                  ))}
                </Stack>
              </Collapse>

              <Button
                onClick={toggleExpand}
                size="small"
                variant="outlined"
                disableElevation
                sx={{
                  fontSize: "0.7rem",
                  padding: "3px 8px",
                  alignSelf: "flex-end",
                }}
              >
                {expanded ? "Mostrar menos" : "Mostrar mais"}
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
});

export default InfosSection;
