import React, { memo } from "react";
import {
  Stack,
  Typography,
  CardContent,
  Card,
} from "investira.react.components";
import { CURVA_TIPO, ATIVO_MERCADO } from "@investirapri/financa.js/lib/enums";
import { InlineInfo } from "../../../components/molecules";

const AtivoInformacoes = memo((props) => {
  const { ativo, pessoa } = props;

  console.log(ativo);

  function fundoValue(pFundo) {
    if (pFundo?.fechado) {
      return pFundo.fechado ? "Fechado" : "Aberto";
    }

    return null;
  }

  return (
    <Stack flexGrow={1}>
      <Card>
        <CardContent
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Stack spacing={2} sx={{ py: 2 }}>
            <Stack direction="row">
              <Stack>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  sx={{ fontWeight: 600 }}
                >
                  Informações do Ativo
                </Typography>
              </Stack>
              <Stack flexGrow="1"></Stack>
            </Stack>
            <Stack direction="column" sx={{ gap: 1 }}>
              <InlineInfo label="Ativo" value={ativo.ativo} />
              <InlineInfo label="ISIN" value={ativo.isin} />
              <InlineInfo label="Ticker" value={ativo.ticker} />
              {/* ADD: Tipode fundo [fechado/aberto]*/}
              <InlineInfo label="Fundo" value={fundoValue(ativo.fundo)} />

              {/* TODO: testar ativo de renda fixa */}
              <InlineInfo label="Série" value={ativo.serie} />
              <InlineInfo label="Curva" value={ativo.curva_id} />
              {/* <InlineInfo
                label="Juros"
                value={ativo?.titulo?.evento?.juros?.periodicidade}
              /> */}

              <InlineInfo label="Emissor" value={ativo.emissor?.pessoa} />
              <InlineInfo
                label="Mercado"
                value={ATIVO_MERCADO[ativo.mercado].descricao}
              />
              {/* <InlineInfo label="Espécie" value={ativo?.acao?.especie} /> */}

              {/* TODO: testar ativo de renda fixa */}
              <InlineInfo
                label="Emissão"
                value={ativo.emissao}
                variant="date"
              />
              <InlineInfo
                label="Vencimento"
                value={ativo.vencimento}
                variant="date"
              />

              {/* <InlineInfo
                label="Setor"
                value={pessoa.setor?.top_setor}
                searchProps={{
                  path: "/empresas",
                  search: {
                    top_setor_id: pessoa.top_setor_id,
                    
                  },
                }}
              /> */}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
});

AtivoInformacoes.displayName = "AtivoInformacoes";

export default AtivoInformacoes;
