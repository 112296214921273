import React, { memo } from "react";
import PropTypes from "prop-types";
import { Stack, Container } from "investira.react.components";
import { HeaderTemplate } from "../";
import { Head } from "../../molecules";
import { Breadcrumbs } from "../../organisms";

const HEADER_HEIGHT = 144;

const PageTemplate = memo((props) => {
  const {
    title,
    subtitle,
    headerRenderActions,
    scrollable = true,
    children,
    maxWidth = false,
  } = props;

  return (
    <>
      <Head title={title}></Head>
      <Stack
        id="page"
        flexGrow={1}
        sx={{
          height: "100%",
          //paddingTop: 3,
        }}
      >
        <Container maxWidth={maxWidth} sx={{ height: "100%", pb: 8, pt: 3 }}>
          <Breadcrumbs />
          <HeaderTemplate
            title={title}
            //subtitle={subtitle}
            renderActions={headerRenderActions}
          />
          {scrollable ? (
            <Stack
              id="scroll"
              sx={{
                flex: "1 1 auto",
                WebkitOverflowScrolling: "touch",
                scrollBehavior: "smooth",
                overflowY: "scroll",
                overflowX: "hidden",
                minHeight: 0,
                position: "relative",
                marginTop: 0,
                paddingBottom: 3,
              }}
            >
              {children}
            </Stack>
          ) : (
            <Stack
              id="content"
              sx={{
                height: `calc(100% - ${HEADER_HEIGHT}px)`,
              }}
            >
              {children}
            </Stack>
          )}
        </Container>
      </Stack>
    </>
  );
});

PageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  scrollable: PropTypes.bool,
  headerRenderActions: PropTypes.node,
};

PageTemplate.displayName = "PageTemplate";

export default PageTemplate;
