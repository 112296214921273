import React, { memo } from "react";
import {
  Stack,
  Typography,
  Chip,
  useMediaQuery,
} from "investira.react.components";
import { displays } from "investira.react.lib";
import { PessoaImage } from "../../molecules";
import { validators } from "investira.sdk";
import { MutedCopy } from "../../molecules";
import utils from "../../../utils";

const SearchEmpresaItem = memo((props) => {
  const { pessoa, onClick } = props;
  const xPessoaTipoList = utils.helpers.getPessoaTipoById(
    pessoa.pessoa_tipo_id
  );
  const isUpMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Stack
      id={pessoa.pessoa_id}
      key={pessoa.pessoa_id}
      direction="row"
      flexGrow={1}
      spacing={3}
      alignItems="flex-start"
      component="li"
      onClick={onClick}
      sx={{
        px: 2,
        py: 2,
        cursor: "pointer",
        "&:hover": {
          color: "gray",
          backgroundColor: "background.main",
        },
        borderBottom: "1px solid",
        borderBottomColor: "background.main",
      }}
    >
      <Stack>
        <PessoaImage
          logoPessoaId={pessoa.logo_pessoa_id}
          alt={pessoa.pessoa}
          hasLogo={pessoa.logo_ok}
          size={36}
        />
      </Stack>
      <Stack flexGrow={1}>
        <Typography
          variant="body2"
          color="textPrimary"
          sx={{ fontWeight: 600 }}
        >
          {pessoa.pessoa_fantasia || pessoa.pessoa}
        </Typography>
        <Stack direction="row" spacing={{ xs: 0, sm: 1 }} mb={1}>
          <Typography
            variant="caption"
            color="textPrimary"
            sx={{
              display: { xs: "none", sm: "inline-block" },
            }}
          >
            {`${pessoa.pessoa}`}
          </Typography>
          {pessoa.cnpj && (
            <MutedCopy value={pessoa.cnpj}>
              <Typography variant="caption" color="textSecondary">
                {`${displays.cnpj(pessoa.cnpj)}`}
              </Typography>
            </MutedCopy>
          )}
        </Stack>
        {/* {!validators.isEmpty(pessoa.setor) && (
          <Stack direction="row" spacing={{ xs: 0, sm: 1 }}>
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{
                display: { xs: "none", sm: "inline-block" },
              }}
            >
              {`${pessoa.setor.setor}`}
            </Typography>
          </Stack>
        )} */}

        {!validators.isEmpty(pessoa.top_setor) && (
          <Stack
            direction="row"
            sx={{ gap: "8px 8px" }}
            flexWrap="wrap"
            spacing={{ xs: 0, sm: 1 }}
          >
            {pessoa.top_setor && (
              <Chip
                label={pessoa.top_setor}
                size="small"
                color="primary"
                sx={{ display: { xs: "flex", md: "none" } }}
              />
            )}
            {!validators.isEmpty(pessoa.setor) && (
              <Chip
                key={pessoa.setor}
                label={pessoa.setor}
                size="small"
                color="secondary"
                sx={{
                  maxWidth: {
                    xs: "200px",
                    sm: "400px",
                    lg: "100%",
                  },
                }}
              />
            )}
            {xPessoaTipoList.map((xPessoaTipo) => {
              return (
                <Chip
                  key={xPessoaTipo.descricao}
                  label={xPessoaTipo.descricao}
                  size="small"
                  color="secondary"
                  sx={{ display: { xs: "none", md: "flex" } }}
                />
              );
            })}
            {pessoa.b3?.codigo_emissor && (
              <Chip
                label={pessoa.b3?.codigo_emissor}
                size="small"
                color="secondary"
                sx={{ display: { xs: "none", md: "flex" } }}
              />
            )}
          </Stack>
        )}
      </Stack>
      {isUpMd && (
        <Stack>
          <Stack justifyContent="end">
            <Chip label={pessoa.top_setor} size="small" color="primary" />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
});

export default SearchEmpresaItem;
