import React, { memo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Stack,
  Icon,
  Tooltip,
  CopyToClipboard,
  Box,
  Divider,
} from "investira.react.components";

import { MutedCopy } from "../";
import { displays } from "investira.react.lib";
import { validators, formats, objects } from "investira.sdk";
import utils from "../../../utils";

const SearchLink = styled(Link)(() => ({
  display: "inline-block",
  position: "relative",
  pointerEvents: "all",
  verticalAlign: "top",
  border: 0,
  height: "16px",
  width: "16px",
  margin: "0 4px",
  "& > svg": {
    position: "absolute",
    top: 0,
    left: 0,
  },
}));

const Info = memo((props) => {
  const {
    label,
    labelProps = {},
    value,
    variant = null,
    type = "row",
    formatDate = "DD/MMM/YYYY",
    minWidth = "80px",
    description,
    descriptionProps = {},
    valueProps = {},
    helpText = null,
    copy = "none",
    searchProps = {},
    divider = true,
    icon = null,
    decimais = 2,
    modal = {},
  } = props;

  if (validators.isEmpty(value)) {
    return null;
  }

  function renderValue(pValue, pVariant) {
    let xClipboard = pValue;

    let xProps = {
      color: "textPrimary",
      variant: props.type === "row" ? "caption" : "body1",
      ...valueProps,
      sx: {
        display: "block",
        fontWeight: "500",
        minWidth: minWidth,

        ...valueProps.sx,
      },
      children: pValue,
    };

    if (pVariant === "tel") {
      const xTel = pValue.replace(/\D/g, "");

      xProps = {
        ...xProps,
        href: `tel:+${xTel}`,
        component: "a",
        color: "primary",
      };

      xClipboard = xTel;
    }

    if (pVariant === "email") {
      xProps = {
        ...xProps,
        href: `mailto:${pValue}`,
        rel: "external",
        component: "a",
        color: "primary",
      };
    }

    if (pVariant === "site") {
      const xUrl = utils.typo.removeUrlProtocol(pValue.toLowerCase());

      xClipboard = `https://${xUrl}`;

      xProps = {
        ...xProps,
        href: xClipboard,
        rel: "external",
        component: "a",
        children: xUrl,
        color: "primary",
      };
    }

    if (pVariant === "bool") {
      xClipboard = Boolean(pValue) ? "Sim" : "Não";

      xProps = {
        ...xProps,
        children: xClipboard,
      };
    }

    if (pVariant === "date") {
      xClipboard = formats.formatDateCustom(value, formatDate);

      xProps = {
        ...xProps,
        children: xClipboard,
      };
    }

    if (pVariant === "address") {
      const { bairro, cep, cidade, complemento, logradouro } = value;

      xClipboard = `${logradouro} ${complemento}, ${bairro}, ${cidade} - ${displays.cep(
        cep
      )}`;

      xProps = {
        ...xProps,
        children: xClipboard,
      };
    }

    if (pVariant === "cnpj") {
      xClipboard = value;

      xProps = {
        ...xProps,
        children: displays.cnpj(value),
      };
    }

    if (pVariant === "currency") {
      let xValue = value;

      if (!value || isNaN(value) || !isFinite(value)) {
        xValue = 0;
      }

      xClipboard = formats.formatNumber(xValue, decimais, true, true);

      const xDecimais = decimais > 6 ? 6 : decimais;

      xProps = {
        ...xProps,
        children: formats.formatNumber(xValue, xDecimais, true, true),
      };
    }

    if (pVariant === "percentual") {
      let xValue = value;

      if (!value || isNaN(value) || !isFinite(value)) {
        xValue = 0;
      }

      xClipboard = formats.formatNumber(xValue, 2, true, false);

      xProps = {
        ...xProps,
        children: `${formats.formatNumber(xValue, 2, true, false)}%`,
      };
    }

    return { clipboard: xClipboard, component: <Typography {...xProps} /> };
  }

  function renderSearch(pSearchProps) {
    if (!validators.isEmpty(pSearchProps)) {
      const { path, search } = pSearchProps;
      const xQuery = objects.objectToQueryString(search);

      const xUrl = `${path}${xQuery}`;

      return (
        <Stack>
          <SearchLink to={xUrl} rel="search">
            <Icon iconName="find" size={16} color="primary" />
          </SearchLink>
        </Stack>
      );
    }
  }

  const { clipboard, component } = renderValue(value, variant);

  if (type === "row") {
    return (
      <>
        <Stack flexGrow={1} direction="row" spacing={2} gap={2}>
          <Stack flexGrow={1} direction="row" spacing={2} minWidth={"120px"}>
            {icon}
            <Typography
              variant={props.type === "row" ? "caption" : "body1"}
              color="textPrimary"
              {...labelProps}
            >
              {label}
            </Typography>
          </Stack>
          <Stack
            flexGrow={1}
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            maxWidth={"50%"}
          >
            {copy === "none" && <Stack flexGrow={1}>{component}</Stack>}

            {copy === "muted" && (
              <Stack flexGrow={1}>
                <MutedCopy value={clipboard}>{component}</MutedCopy>
              </Stack>
            )}

            {copy === "icon" && (
              <>
                <Stack flexGrow={1}>{component}</Stack>
                <Stack>
                  <CopyToClipboard value={clipboard} />
                </Stack>
              </>
            )}

            {helpText && (
              <Stack alignItems="center" justifyContent="center">
                <Tooltip title={helpText}>
                  <Box sx={{ width: "16px", height: "16px" }}>
                    <Icon iconName="information" size={16} color="primary" />
                  </Box>
                </Tooltip>
              </Stack>
            )}

            {renderSearch(searchProps)}

            {!validators.isEmpty(modal) && (
              <Stack alignItems="center" justifyContent="center">
                <Box
                  sx={{ width: "16px", height: "16px", cursor: "pointer" }}
                  onClick={modal.onClick}
                >
                  <Icon
                    iconName={modal.iconName || "others"}
                    size={16}
                    color="primary"
                  />
                </Box>
              </Stack>
            )}
          </Stack>
        </Stack>

        {divider && <Divider />}
      </>
    );
  }

  return (
    <Stack
      flexGrow={1}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        mb={1}
        sx={{ height: "30px" }}
      >
        <Stack flexGrow={1} direction="row" spacing={2} alignItems="center">
          {icon}
          <Typography variant="caption" color="textPrimary" {...labelProps}>
            {label}
          </Typography>
        </Stack>
        {renderSearch(searchProps)}
      </Stack>

      {description && (
        <Stack flexGrow={1} justifyContent="flex-end">
          <Typography
            variant="caption"
            color="textSecondary"
            {...descriptionProps}
          >
            {description}
          </Typography>
        </Stack>
      )}

      <Stack direction="row" alignItems="center" spacing={1}>
        {copy === "none" && <Stack flexGrow={1}>{component}</Stack>}

        {copy === "muted" && (
          <Stack flexGrow={1}>
            <MutedCopy value={clipboard}>{component}</MutedCopy>
          </Stack>
        )}

        {copy === "icon" && (
          <>
            <Stack flexGrow={1}>{component}</Stack>
            <Stack>{<CopyToClipboard value={clipboard} />}</Stack>
          </>
        )}

        {helpText && (
          <Stack alignItems="center" justifyContent="center">
            <Tooltip title={helpText}>
              <Box sx={{ width: "16px", height: "16px" }}>
                <Icon iconName="information" size={16} color="primary" />
              </Box>
            </Tooltip>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
});

Info.propTypes = {
  variant: PropTypes.oneOf([
    "tel",
    "email",
    "site",
    "pf",
    "bool",
    "date",
    "currency",
    "cnpj",
    "address",
    "percentual",
  ]),
  copy: PropTypes.oneOf(["icon", "muted", "none"]),
};

Info.displayName = "Info";

export default Info;
