import React, { memo } from "react";
import { Stack, Typography, Button } from "investira.react.components";
import { Head } from "../../components/molecules";
import logo from "../../assets/images/investira_biz.svg";
import { Link } from "react-router-dom";

const PageNotFound = memo(() => {
  return (
    <>
      <Head title="Home">
        <meta
          name="description"
          content="Sistemas para fundos de investimentos, empresas e instituições financeiras dos mais diversos portes como Fundos de Pensão, Bancos, Assets Managements e Family Offices."
        />
      </Head>
      <Stack
        id="page"
        flexGrow={1}
        sx={{
          height: "100%",
          //paddingTop: 3,
        }}
      >
        <Stack alignItems="center" justifyContent="center" height="100%">
          <Stack spacing={3}>
            <Stack flexGrow={1} mb={4}>
              <img src={logo} alt="Investirabiz" width="323px" />
            </Stack>

            <Stack mb={2} spacing={1}>
              <Typography color="textPrimary">
                <b>ERROR 404</b>
              </Typography>
              <Typography
                color="textPrimary"
                sx={{ fontSize: "7rem", fontWeight: 600 }}
              >
                Oops!
              </Typography>
              <Typography
                color="textPrimary"
                variant="body2"
                sx={{ letterSpacing: "0.08rem" }}
              >
                Esta não é a página que você procurava
              </Typography>
            </Stack>

            <Stack>
              <Button
                variant="contained"
                component={Link}
                to="/"
                size="large"
                fullWidth
              >
                Ir para página inicial
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
});

export default PageNotFound;
