import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { strings } from "investira.sdk";
import { PaginationTemplate } from "../../components/templates";
import { Search } from "../../components/organisms";
import services from "../../services";
import EmpresasItem from "./EmpresasItem";

import EmpresaFilter from "./EmpresaFilter";

const Empresas = memo(() => {
  const empresasSearch = useSelector((store) => store.empresas.search);
  const location = useLocation();
  const search = strings.querystringToObject(location.search);

  function sort(pSearch) {
    let xValues =
      "relevancia DESC, top_setor_id ASC, setor_id DESC, pessoa_fantasia ASC";

    if ("pesquisa" in pSearch) {
      xValues =
        "relevancia DESC, __mr__ DESC, top_setor_id ASC, setor_id DESC, pessoa_fantasia ASC";
    }

    return xValues;
  }

  //Render
  return (
    <PaginationTemplate
      id="empresas"
      title="Empresas"
      description="Lista das empresas. Conheça o patrimônio líquido, capital social, balanço, ativos relacionados, filiais e participação societária."
      dataRead={services.pessoas.list}
      dataReadParams={{
        cancelado: "null",
        sort: sort(search),
      }}
      searchComponent={Search}
      //termsComponent={EmpresaTerms}
      filterComponent={EmpresaFilter}
      listItem={EmpresasItem}
      listItemKey="pessoa_id"
      pageSize={25}
      match={"empresas/*"}
      maxWidth="xl"
      state={empresasSearch}
    />
  );
});

Empresas.displayName = "Empresas";

export default Empresas;
