import React, { useEffect } from "react";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";
import { formats } from "investira.sdk";
import {
  ThemeProvider,
  PersistGate,
  DndProvider,
  withThemeLocale,
  IconSprite,
  Box,
} from "investira.react.components";
import { themeLight } from "../styles/lightTheme";
import { themeDark } from "../styles/darkTheme";
import withClearCache from "./ClearCache";
import { store, persistor } from "../store";
import utils from "../utils";
import NavigationProvider from "../contexts/NavigationContext";
import Root from "./Root";
import "../assets/style/app.scss";
import * as actions from "../store/actions";

const isProduction = process.env.NODE_ENV === "production";

isProduction && ReactGA.initialize("G-X3W6GWHHY3");

// Add polyfill aos navegadores sem suporte a scroll-behavior (Isso é para você safari!)
if (!("scrollBehavior" in document.documentElement.style)) {
  (async function () {
    await import("scroll-behavior-polyfill");
  })();
}

// Remove console.log em produção
utils.build.noLog();
utils.build.noTrace();

// Configura a localização;
formats.locale("pt-br");

function Main(props) {
  if (window.Cypress) {
    window.store = store;
    window.store.actions = actions;
  }

  return (
    <DndProvider>
      <ThemeProvider theme={themeLight}>
        {/* <ThemeProvider theme={themeDark}> */}
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <NavigationProvider>
              <Box
                id="app"
                className="app"
                sx={{
                  width: "100vw",
                  backgroundColor: (theme) => theme.palette.background.main,
                }}
              >
                <Root />
                <IconSprite />
              </Box>
            </NavigationProvider>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </DndProvider>
  );
}

const ClearCacheComponent = withClearCache(
  // withThemeLocale(Main, themeDark, "ptBR")
  withThemeLocale(Main, themeLight, "ptBR")
);

const App = () => {
  useEffect(() => {
    // const noScrollBody = (function () {
    //   document.body.addEventListener("touchmove", (pEvent) => {
    //     document.body.scrollTop = 0;
    //     pEvent.preventDefault();
    //   });
    // })();

    return () => {
      document.body.removeEventListener("touchmove", (pEvent) => {
        document.body.scrollTop = 0;
        pEvent.preventDefault();
      });
    };
  }, []);

  return <ClearCacheComponent />;
};

App.displayName = "App";

export default App;
